import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: 222px;
    }
    .speech-bubble-mini{
      margin-top: -345px;
      margin-left: 100px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 100%;
      height: 465px;
    }
    .speech-bubble-mini{
      margin-top: -440px;
      margin-left: 238px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 100%;
      height: 622px;
    }
    .speech-bubble-mini{
      margin-top: -592px;
      margin-left: 360px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 84%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: 390px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -300px;
      margin-left: 520px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 99%;
    }
    .speech-bubble-mini{
      margin-top: -400px;
      margin-left: 720px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgMujer = styled.div`
  position: absolute;
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 34px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -70%;
    margin-left: 35%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -33%;
    margin-left: 35%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -37%;
    margin-left: 35%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 100px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -5%;
    margin-left: 35%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 120px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -6%;
    margin-left: 35%;
  }
  @media(min-width: 1920px){
    width: 170px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -7%;
    margin-left: 35%;
  }
`
export const ImgMujer = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgCabeza = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 50px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -84%;
    margin-left: -49%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 120px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -54%;
    margin-left: -43%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 160px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -54%;
    margin-left: -41%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 140px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -18%;
    margin-left: -35%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 140px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -18%;
    margin-left: -35%;
  }
  @media(min-width: 1920px){
    width: 200px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -20%;
    margin-left: -35%;
  }
`
export const ImgCabeza = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgPerro = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 100px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -79%;
    margin-left: 1%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 170px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -42%;
    margin-left: 5%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 200px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -42%;
    margin-left: 7%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 200px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -13%;
    margin-left: 5%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 240px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -14%;
    margin-left: 7%;
  }
  @media(min-width: 1920px){
    width: 280px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -13%;
    margin-left: 8%;
  }
`
export const ImgPerro = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgEscalera = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 84px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -70%;
    margin-left: -46%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 210px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -37%;
    margin-left: -50%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 255px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -36%;
    margin-left: -48%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 230px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -5%;
    margin-left: -40%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 300px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -4%;
    margin-left: -46%;
  }
  @media(min-width: 1920px){
    width: 400px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -6%;
    margin-left: -46%;
  }
`
export const ImgEscalera = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreParado = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 60px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -73%;
    margin-left: 23%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 110px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -37%;
    margin-left: 26%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 140px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -39%;
    margin-left: 27%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 130px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -5%;
    margin-left: 24%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 150px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -5%;
    margin-left: 30%;
  }
  @media(min-width: 1920px){
    width: 230px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -5%;
    margin-left: 28%;
  }
`
export const ImgHombreParado = styled.img`
  width: 100%;
  height: 100%;
`
