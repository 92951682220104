import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgMujer, ImgMujer, ContImgCabeza, ImgCabeza, ContImgPerro, ImgPerro, ContImgEscalera, ImgEscalera, ContImgHombreParado, ImgHombreParado} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap, TweenMax} from "gsap";
import {SpeechBubble} from '../../SpeechBubbles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import useSound from 'use-sound';
import imgMujer from '../../../images/obras/obra-14/mujer.png';
import imgCabeza from '../../../images/obras/obra-14/cabeza.png';
import imgPerro from '../../../images/obras/obra-14/perro.png';
import imgEscalera from '../../../images/obras/obra-14/escalera.png';
import imgHombreParado from '../../../images/obras/obra-14/hombre-parado.png';
import soundMujer from '../../../images/obras/obra-14/walk.mp3';
import soundBark from '../../../images/obras/obra-14/bark.mp3';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 15,
  bottom: null,
  left: 13,
};

const Page14Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMujer, setInitAnimatedMujer] = useState(false);
  const [initAnimatedCabeza, setInitAnimatedCabeza] = useState(false);
  const [initAnimatedPerro, setInitAnimatedPerro] = useState(false);
  const [initAnimatedEscalera, setInitAnimatedEscalera] = useState(false);
  const [initAnimatedHombreParado, setInitAnimatedHombreParado] = useState(false);
  const [initSoundMujer, setInitSoundMujer] = useState(false);
  const [initSoundBark, setInitSoundBark] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-14/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };
  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>esa borrosa patria de los muertos.</div>
            <div>Hablamos siempre de otras cosas.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnReLaunchAll = () => {
    fnReLaunchMujer(0);
    fnReLaunchCabeza(0);
    fnReLaunchPerro(0);
    fnReLaunchEscalera(0);
    fnReLaunchHombreParado(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchMujer = (noReplay = 1) => {
    setInitAnimatedMujer(false);
    setInitSoundMujer(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMujer(true);
      }, 1000);
    }
  };
  const fnReLaunchCabeza = (noReplay = 1) => {
    setInitAnimatedCabeza(false);
    // setInitSoundCabeza(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedCabeza(true);
      }, 100);
    }
  };
  const fnReLaunchPerro = (noReplay = 1) => {
    setInitAnimatedPerro(false);
    setInitSoundBark(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedPerro(true);
      }, 1000);
    }
  };
  const fnReLaunchEscalera = (noReplay = 1) => {
    setInitAnimatedEscalera(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedEscalera(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreParado = (noReplay = 1) => {
    setInitAnimatedHombreParado(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreParado(true);
      }, 1000);
    }
  };

  const fnAnimateMujer = () => {
    return (
      <ContImgMujer className={'img-animated'} onClick={() => fnReLaunchMujer()}>
        {
          initAnimatedMujer &&
          <ImgMujer
            id={'animate-mujer'}
            className={'animate-mujer'}
            src={imgMujer}
            alt="Mujer"
          />
        }
      </ContImgMujer>
    )
  }
  const fnAnimateCabeza = () => {
    return (
      <ContImgCabeza className={'img-animated'} onClick={() => fnReLaunchCabeza()}>
        {
          initAnimatedCabeza &&
          <ImgCabeza
            id={'animate-cabeza'}
            className={'animate-cabeza'}
            src={imgCabeza}
            alt="Cabeza"
          />
        }
      </ContImgCabeza>
    )
  }
  const fnAnimatePerro = () => {
    return (
      <ContImgPerro className={'img-animated'} onClick={() => fnReLaunchPerro()}>
        {
          initAnimatedPerro &&
          <ImgPerro
            id={'animate-perro'}
            className={'animate-perro'}
            src={imgPerro}
            alt="Perro"
          />
        }
      </ContImgPerro>
    )
  }
  const fnAnimateEscalera = () => {
    return (
      <ContImgEscalera className={'img-animated'} onClick={() => fnReLaunchEscalera()}>
        {
          initAnimatedEscalera &&
          <ImgPerro
            id={'animate-escalera'}
            className={'animate-escalera'}
            src={imgEscalera}
            alt="Escalera"
          />
        }
      </ContImgEscalera>
    )
  }
  const fnAnimateHombreParado = () => {
    return (
      <ContImgHombreParado className={'img-animated'} onClick={() => fnReLaunchHombreParado()}>
        {
          initAnimatedHombreParado &&
          <ImgPerro
            id={'animate-hombre-parado'}
            className={'animate-hombre-parado'}
            src={imgHombreParado}
            alt="Hombre Parado"
          />
        }
      </ContImgHombreParado>
    )
  }

  const [playMujer, { stopMujer }] = useSound(
    soundMujer,
    { volume: 0.1 }
  );
  const [playBark, { stopBark }] = useSound(
    soundBark,
    { volume: 0.1 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedMujer(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedEscalera(true);
    }, 6000);

    setTimeout(function (){
      setInitAnimatedCabeza(true);
    }, 7000);

    setTimeout(function (){
      setInitAnimatedPerro(true);
    }, 9000);

    setTimeout(function (){
      setInitAnimatedHombreParado(true);
    }, 12000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 15000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);

    const winWidth = window.innerWidth;
    let arPosMujer = {};
    let arPosCabeza = {};

    if(winWidth < 767){
      arPosMujer = {x: -150}
      arPosCabeza = {x: 50}
    } else if(winWidth < 1023){
      arPosMujer = {x: -320}
      arPosCabeza = {x: 50}
    } else if(winWidth < 1215){
      arPosMujer = {x: -420}
      arPosCabeza = {x: 50}
    } else if(winWidth < 1407){
      arPosMujer = {x: -500}
      arPosCabeza = {x: 50}
    } else if(winWidth < 1919){
      arPosMujer = {x: -555}
      arPosCabeza = {x: 50}
    } else {
      arPosMujer = {x: -768}
      arPosCabeza = {x: 50}
    }

    if(initAnimatedMujer){
      gsap.to(".animate-mujer", {
        x: arPosMujer.x,
        duration: 3,
        delay: 0,
      });

      if(!initSoundMujer){
        playMujer();
        setInitSoundMujer(true);
      }
    }

    if(initAnimatedCabeza){
      gsap.to(".animate-cabeza", {
        x: arPosCabeza.x,
        duration: 3,
        delay: 0,
      });
      gsap.to(".animate-cabeza", {
        duration: 3,
        delay: 1,
        rotation: function(i, target, list) {
          return 25;
        },
      });
    }

    if(initAnimatedPerro){
      TweenMax.fromTo(".animate-perro", 0.1, {x:-2}, {x:2, clearProps:"x", repeat: -1})

      if(!initSoundBark){
        playBark();
        setInitSoundBark(true);
      }
    }

    if(initAnimatedEscalera){
      gsap.to(".animate-escalera", {
        duration: 2,
        delay: 0,
      });
      gsap.to(".animate-escalera", {
        duration: 3,
        delay: 1,
      });
    }

    if(initAnimatedHombreParado){
      gsap.to(".animate-hombre-parado", {
        duration: 2,
        delay: 0,
      });
      gsap.to(".animate-hombre-parado", {
        duration: 3,
        delay: 1,
      });
    }
  }, [initAnimatedMujer, initAnimatedCabeza, initAnimatedPerro, initAnimatedEscalera, initAnimatedHombreParado]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateMujer()}
            {fnAnimateCabeza()}
            {fnAnimatePerro()}
            {fnAnimateEscalera()}
            {fnAnimateHombreParado()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page14Comp;
